<template>

    <div class="cu-questions-screen">
 
        <b-modal id="showFindAndReplaceModal" ref="showFindAndReplaceModal" title="Find & Replace" size="lg"
            @ok="onConfirmReplacement" @hide="onFindReplaceModalClose" ok-title="Save" :ok-disabled="isSaveDisabled">
            <form @submit.prevent="onFind">
                <b-form-group label="Find Text" label-for="find-input">
                    <b-form-input id="find-input" v-model="findText" placeholder="Enter text to find"></b-form-input>
                </b-form-group>

                <b-form-group label="Replace With" label-for="replace-input">
                    <b-form-input id="replace-input" v-model="replaceText"
                        placeholder="Enter replacement text"></b-form-input>
                </b-form-group>

                <div class="d-flex flex-row">
                    <div class="w-50" style="overflow-wrap: break-word; white-space: pre-wrap;">
                        <h5>Match Results</h5>
                        <div v-if="foundTexts.length > 0">
                            <p class="mb-0" v-for="(item, index) in foundTexts" :key="index"
                                v-html="item[selectedColumn]"></p>
                        </div>
                        <p v-else>No match found</p>
                    </div>
                    <div v-if="modifiedTexts.length > 0" class="w-50"
                        style="overflow-wrap: break-word; white-space: pre-wrap;">
                        <h5>Text after Replacement</h5>
                        <div>
                            <p class="mb-0" v-for="(item, index) in modifiedTexts" :key="index"
                                v-html="item[selectedColumn]">
                            </p>
                        </div>
                    </div>

                </div>
                <div class="d-flex justify-start mt-2">
                    <b-button variant="primary mr-2" @click="onFind" :disabled="!findText">Find</b-button>
                    <b-button variant="danger" @click="onReplace"
                        :disabled="!foundTexts.length > 0 || !replaceText || !findText">Replace</b-button>

                </div>
            </form>

        </b-modal>


        <b-modal id="ViewQuestionJsonModal" hide-footer size="lg" centered title="Question JSON">
            <div class="modal_content">
                <div class="d-flex w-100">
                    <div class="w-100">
                        <textarea readonly v-model="singleQuestionJson" style="
                width: 100%;
                height: 500px;
                background: #1e1e1e;
                color: #ce723b;
              " class="p-1"></textarea>
                    </div>

                </div>

                <div class="modal-footer">
                    <button type="button" @click="closeViewJsonModel" class="btn btn-secondary" data-dismiss="modal">
                        Close
                    </button>
                </div>
            </div>
        </b-modal>
 
        <div class="row">
            <div class="col-6 d-flex align-items-center">
                <div class="d-flex align-items-center">
                    <div class="qu-icon-radius d-flex align-items-center">
                        <feather-icon icon="PlusIcon" size="17" class="qu-icon-grey" />
                    </div>
                    <p class="fs-105 fs-options-1 qu-fw-400 mb-0 ml-1" style="margin-top: 4px">
                        MCQs
                    </p>

                    <PreviewQuestions :questions="questions"  @preview-modal-closed="handlePreviewModalClose" />
                    <MoveQuestionsModal :questionsToMove="selectedQuestionsToMove" @getUpdatedQuestionsData="getUpdatedData"
                    @ResetQuestions="ResetQuestionsCheckBox"/>
                     
                    <QuestionFeedbackModal v-if="isQuestionFeedbackModalOpen" :question="selectedQuestionForFeedback"
                        :isModalOpen="isQuestionFeedbackModalOpen" @close="closeFeedbackModal"
                        @saveFeedbackResponse="handleFeedbackResponse" />
                    <QuestionLogModal :question="selectedQuestionForLog._id"  />

                </div>
            </div>


            <div class="col-6">
                <div class="
                        d-flex
                        flex-row
                        gap-25
                        align-items-center
                        justify-content-end
                    ">
<template>
                    <div>
                        <b-dropdown text="Action" variant="primary" :disabled="isDisableAction">
                        <b-dropdown-item @click="moveQuestions">Move Questions</b-dropdown-item>
                        </b-dropdown>
                    </div>
                    </template>
                    <span v-click-outside="hideFields" id="hide-show-fields">
                        <div class="qu-fc-color text-nowrap" @click="hideShowField" id="hide-show-btn">
                            Show/Hide Fields
                            <feather-icon icon="ChevronDownIcon" />
                        </div>
                        <div v-if="isFieldDropdown" class="position-absolute p-1" style="
                                width: 200px;
                                height: 110px;
                                background-color: #fff;
                                border-radius: 10px;
                                margin-top: 5px;
                            ">
                            <div class="d-flex">
                                <b-form-checkbox v-model="isTitle">
                                </b-form-checkbox>
                                Title
                            </div>
                            <div class="d-flex mt-1">
                                <b-form-checkbox v-model="isDescription">
                                </b-form-checkbox>
                                Description
                            </div>
                            <div class="d-flex mt-1">
                                <b-form-checkbox v-model="isOption">
                                </b-form-checkbox>
                                Option
                            </div>
                        </div>
                    </span>
                    <ExportQuestionForGpt :questions="questions" />
                    <AddQuestionGptResponse :error="errorMsg" @createQuestionJson="createQuestionJson"
                        @resetGptModel="resetGptModel"
                        v-if="permissions.permission_manage_topics || permissions.permission_manage_topics_content" />

                    <div v-if="permissions.permission_manage_topics || permissions.permission_manage_topics_content"
                        class="
                            align-items-center
                            border-grey
                            qu-fc-color
                            curser-pointer
                            d-flex
                        " @click="confirmDelete">
                        <feather-icon icon="TrashIcon" />
                        <span class="text-nowrap qu-margin-left-10">Delete</span>
                    </div>
                    <div>
                        <b-input-group>
                            <b-button variant="primary" @click="addNewQuestion"
                                v-if="permissions.permission_manage_topics || permissions.permission_manage_topics_content">
                                New
                            </b-button>
                        </b-input-group>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-2 overflow-auto" style="padding-bottom:5px;">
            <div class="table-container custom-table-questions">
                <div class="table-header" style="border-top: 1px solid #ebe9f1">
                    <div v-if="!isStickyNumber" class="header-cell cu-padding" style="width: 130px"></div>
                    <div v-if="!isStickyNumber" class="
                            header-cell
                            text-center
                            cu-padding
                            position-relative
                        " style="width: 150px">
                        <img src="/setting1.png" alt="" class="table-heading-margin img-width" />
                        <span>Setting</span>
                        <span class="position-absolute" style="right: 5px">
                            <feather-icon class="curser-pointer" @click="updateStickFunction" style="color: #7367f0"
                                icon="BookmarkIcon" />
                        </span>
                    </div>
                    <div v-if="isStickyNumber || isStickyTitle" :class="{
                        'sticky-column': isStickyNumber || isStickyTitle,
                    }">
                        <div v-if="isStickyNumber" class="header-cell cu-padding" style="width: 120px"
                            :class="{ 'sticky-column': isStickyNumber }"></div>
                        <div v-if="isStickyNumber" class="
                                header-cell
                                text-center
                                cu-padding
                                position-relative
                            " style="width: 150px">
                            <img src="/setting1.png" alt="" class="table-heading-margin img-width" />
                            <span>Setting</span>
                            <span class="position-absolute" style="right: 5px">
                                <feather-icon class="curser-pointer" @click="updateStickFunction" style="color: #7367f0"
                                    icon="BookmarkIcon" fill="#7367f0" />
                            </span>
                        </div>
                        <div class="
                                header-cell
                                text-center
                                cu-padding
                                position-relative
                            " style="width: 450px" v-if="isStickyTitle && isTitle">
                            <img src="/title.png" alt="" class="table-heading-margin img-width" />
                            <span>Title</span>
                            <span class="position-absolute" style="right: 5px">
                                <feather-icon class="curser-pointer" @click="updateStickTitle" style="color: #7367f0"
                                    icon="BookmarkIcon" fill="#7367f0" />
                            </span>
                        </div>
                    </div>
                    <div class="
                            header-cell
                            text-center
                            cu-padding
                            position-relative
                        " style="width: 450px" v-if="!isStickyTitle && isTitle">
                        <img src="/title.png" alt="" class="table-heading-margin img-width" />
                        <span>Title</span>
                        <span class="position-absolute" style="right: 5px">
                            <feather-icon class="curser-pointer" @click="updateStickTitle" style="color: #7367f0"
                                icon="BookmarkIcon" />
                        </span>

                    </div>
                    <div class="
                            header-cell
                            text-center
                            position-relative
                            cu-padding
                        " :class="{ 'sticky-column': isStickyDescription }" v-if="isDescription"
                        v-for="(n, index) in descriptionCount" :key="index + 'descth'" :style="{ width: '450px' }">
                        <img v-if="index == 0" src="/desc.png" alt="" class="table-heading-margin img-width" />
                        <span v-if="index == 0">Descriptions</span>
                        <span class="position-absolute" style="right: 5px">
                            <feather-icon v-if="index >= 0" class="curser-pointer" @click="addDescription"
                                style="color: #7367f0" icon="PlusIcon" />
                            <feather-icon v-if="index > 0" class="curser-pointer" @click="removeDescription(index)"
                                style="color: red" icon="TrashIcon" />
                        </span>
                    </div>

                    <div class="
                            header-cell
                            text-center
                            position-relative
                            cu-padding
                        " style="width: 225px">
                        <img src="/hint.png" alt="" class="table-heading-margin img-width" />
                        <span>Correct Option Hint</span>
                    </div>

                    <div class="
                            header-cell
                            text-centers
                            position-relative
                            cu-padding
                        " v-if="isOption" v-for="(n, index) in optionCount" :key="index + 'optth'"
                        :style="{ width: '520px' }">
                        <img v-if="index == 0" src="/desc.png" alt="" class="table-heading-margin img-width" />
                        <span v-if="index == 0">Options</span>
                        <span class="position-absolute" style="right: 5px">
                            <feather-icon v-if="index >= 0" class="curser-pointer" @click="addOption"
                                style="color: #7367f0" icon="PlusIcon" />
                            <feather-icon v-if="index > 0" class="curser-pointer" @click="removeOption(index)"
                                style="color: red" icon="TrashIcon" />
                        </span>
                    </div>
                </div>
                <div class="table-body">
                    <div class="d-flex" style="border-top: 1px solid #ebe9f1">
                        <div v-if="!isStickyNumber" class="cell fs-0625" style="width: 70px">
                            <b-form-checkbox class="m-auto" style="width: fit-content"
                                v-on:change="checkUncheckQuestion()" v-model="tableCheckbox"></b-form-checkbox>
                        </div>
                        <div v-if="!isStickyNumber" class="cell fs-0625" style="width: 60px">
                            <p class="m-auto mb-0" style="width: fit-content">
                                #
                            </p>
                        </div>
                        <div v-if="!isStickyNumber" class="cell fs-0625 justify-content-center cu-txt-color-font-weight"
                            style="width: 150px">
                            Theme
                        </div>
                        <div v-if="isStickyNumber || isStickyTitle" class="d-flex flex-row" :class="{
                            'sticky-column':
                                isStickyNumber || isStickyTitle,
                        }">
                            <div v-if="isStickyNumber" class="cell fs-0625" style="width: 60px">
                                <b-form-checkbox class="m-auto" style="width: fit-content"
                                    v-on:change="checkUncheckQuestion()" v-model="tableCheckbox"></b-form-checkbox>
                            </div>
                            <div v-if="isStickyNumber" class="cell fs-0625" style="width: 60px">
                                <p class="m-auto mb-0" style="width: fit-content">
                                    #
                                </p>
                            </div>
                            <div v-if="isStickyNumber"
                                class="cell fs-0625 justify-content-center cu-txt-color-font-weight"
                                style="width: 150px">
                                Theme
                            </div>
                            <div v-if="isStickyTitle" class="d-flex flex-row">
                                <div class="cell fs-0625 justify-content-center cu-txt-color-font-weight" v-if="isTitle"
                                    style="width: 225px">
                                    Question title
                                </div>
                                <div class="cell position-relative fs-0625 justify-content-center cu-txt-color-font-weight"
                                    style="width: 225px" v-if="isTitle">
                                    TTS
                                    <span class="position-absolute" style="right: 5px">
                                        <feather-icon class="curser-pointer" @click="copyQuestionTts"
                                            style="color: #47464a" icon="CopyIcon" />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div v-if="!isStickyTitle" class="d-flex flex-row" :class="{ 'sticky-column': isStickyTitle }">
                            <div class="cell position-relative fs-0625 justify-content-center cu-txt-color-font-weight"
                                v-if="isTitle" style="width: 225px">
                                Question title
                                <span v-if="questions.length > 0" class="position-absolute" style="right: 10px"
                                    v-b-modal.showFindAndReplaceModal @click="setColumnName('title')">
                                    <feather-icon class="curser-pointer" style="color:  #47464a" icon="SearchIcon" />
                                </span>
                            </div>
                            <div class="cell position-relative fs-0625 justify-content-center cu-txt-color-font-weight"
                                style="width: 225px" v-if="isTitle">
                                TTS
                                <span v-if="questions.length > 0" class="position-absolute" style="right: 25px"
                                    v-b-modal.showFindAndReplaceModal @click="setColumnName('titleTts')">
                                    <feather-icon class="curser-pointer" style="color:  #47464a" icon="SearchIcon" />
                                </span>
                                <span class="position-absolute" style="right: 5px">
                                    <feather-icon class="curser-pointer" @click="copyQuestionTts" style="color: #47464a"
                                        icon="CopyIcon" />
                                </span>
                            </div>
                        </div>

                        <div v-for="(i, index) in descriptionCount" v-if="isDescription" class="d-flex flex-row"
                            :class="{ 'sticky-column': isStickyDescription }">
                            <div class="cell fs-0625 justify-content-center cu-txt-color-font-weight"
                                style="width: 225px">
                                Description text {{ i }}
                            </div>
                            <div class="cell position-relative justify-content-center cu-txt-color-font-weight"
                                style="font-size: 10px; width: 225px">
                                Description TTS {{ i }}
                                <span class="position-absolute" style="right: 5px">
                                    <feather-icon class="curser-pointer" @click="copyDescitpionTts(index)"
                                        style="color: #47464a" icon="CopyIcon" />
                                </span>
                            </div>
                        </div>
                        <div class="cell fs-0625 justify-content-center cu-txt-color-font-weight" style="width: 225px">
                         
                            <span>Hint</span>
                        </div>
                        <template v-if="isOption" v-for="(j, index) in optionCount">
                            <div class="cell fs-0625 justify-content-center cu-txt-color-font-weight"
                                style="width: 225px">
                                Option text {{ j }}
                            </div>
                            <div class="cell position-relative fs-0625 justify-content-center cu-txt-color-font-weight"
                                style="width: 225px">
                                Option text TTS {{ j }}
                                <span class="position-absolute" style="right: 5px">
                                    <feather-icon class="curser-pointer" @click="copyOptionTts(index)"
                                        style="color: #47464a" icon="CopyIcon" />
                                </span>
                            </div>
                            <div class="cell fs-0625 justify-content-center cu-txt-color-font-weight"
                                style="width: 70px">
                                Correct {{ j }}
                            </div>
                        </template>
                    </div>
                    <draggable v-model="questions" group="people" @start="onDragStart" @end="onDragEnd"
                        handle=".handle">
                        <div v-for="(question, questionIndex) in questions" :key="questionIndex + 'question'"
                            class="d-flex input-cells hover-question-row" style="border-top: 1px solid #ebe9f1"
                            :class="{ 'qu-fc-color-red': questionColorChange && questionColorChange == question._id ? true : false }"
                            > 
                            <div v-if="!isStickyNumber" class="cell position-relative" :style="{
                                width: '70px',
                                backgroundColor: getBackgroundColor(question._id),
                            }">

                                <div style="
                                            position: absolute;
                                            top: -20px;  
                                            left: 0;
                                            right: 48px;  
                                            display: flex;
                                            flex-direction: column;
                                            justify-content: center;
                                            align-items: center;
                                            z-index: 1;
                                            padding: 5px;  
                                        "
                                         :class="{ 'top-negative-60-for-last-index': questionIndex == questions.length-1 ? true : false }"
                                         >
                                    <feather-icon icon="CopyIcon" @click="duplicateQuestion(question)"
                                        style="color: black; margin-bottom: 3px; cursor: pointer" size="16"
                                        class="move-duplicate-hover" />
                                    <feather-icon icon="MoveIcon"
                                        style="color: black; margin-bottom: 3px; cursor: pointer" size="16"
                                        class="handle move-duplicate-hover" />
                                    <img src="/feedback.png" @click="addFeedback(question)"
                                        style="cursor: pointer; z-index: 999999999 !important; margin-bottom: 2px;"
                                        alt="Feedback" class="move-duplicate-hover feedback-icon" width="15"
                                        height="15" />
                                    <img src="/history.svg" @click="ViewQuestionLog(question)"
                                        v-b-modal.question-log-modal
                                        style="cursor: pointer; z-index: 999999999 !important; margin-bottom: 2px;"
                                        alt="view log" class="move-duplicate-hover feedback-icon" width="17"
                                        height="17" />
                                    <img src="/json.png" v-b-modal.ViewQuestionJsonModal
                                        @click="ViewQuestionJson(question)" style="cursor: pointer;" alt="view json"
                                        class="move-duplicate-hover" width="18" height="18" />
                                </div>
 
                                <b-form-checkbox v-model="question.checkbox" @change="toggleSelectedQuestion()"
                                    class="m-auto" style="width: fit-content; padding-left: 1.5rem !important;">
                                </b-form-checkbox>
                            </div>

                            <div v-if="!isStickyNumber" class="cell" style="width: 60px">
                                <div class="cu-tooltip">
                                    {{ questionIndex + 1 }}
                                </div>
                            </div>
                            <div v-if="!isStickyNumber" class="cell" style="width: 150px">

                                <b-form-select v-model="question.theme" :options="formattedThemes"></b-form-select>
                            </div>
                            <div v-if="isStickyNumber || isStickyTitle" class="d-flex flex-row" :class="{
                                'sticky-column':
                                    isStickyNumber || isStickyTitle,
                            }">
                                <div v-if="isStickyNumber" class="cell position-relative" style="width: 60px">
                                    <feather-icon icon="MoveIcon" style="
                                            position: absolute;
                                            top: 2px;
                                            left: 2px;
                                        " size="12" class="handle move-duplicate-hover" />
                                    <feather-icon icon="CopyIcon" @click="duplicateQuestion(question)" style="
                                            position: absolute;
                                            bottom: 5px;
                                            left: 2px;
                                        " size="14" class="move-duplicate-hover" />
                                    <b-form-checkbox v-model="question.checkbox" @change="toggleSelectedQuestion()"
                                        class="m-auto" style="width: fit-content"></b-form-checkbox>
                                </div>
                                <div v-if="isStickyNumber" class="cell" style="width: 60px">
                                    <div class="cu-tooltip">
                                        {{ question }}
                                    </div>
                                </div>
                                <div v-if="isStickyNumber" class="cell" style="width: 150px">
                                    <b-form-select v-model="question.theme" :options="formattedThemes"></b-form-select>
                                </div>
                                <div v-if="isStickyTitle" class="d-flex flex-row">
                                    <div contenteditable="false" class="cell p-0 position-relative " v-if="isTitle"
                                        style="width: 225px">
                                        <div class="qu-remove-border editable-div single-line" contenteditable="true"
                                            v-html="question.title" @focus="onFocus"
                                            @blur="onBlur($event, questionIndex, 'title')">
                                        </div>
                                    </div>
                                    <div class="cell p-0 position-relative" v-if="isTitle" style="width: 225px">
                                        <div class="qu-remove-border editable-div single-line" contenteditable="true"
                                            v-html="question.titleTts" @focus="onFocus"
                                            @blur="onBlur($event, questionIndex, 'titleTts')">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="!isStickyTitle" class="d-flex flex-row">
                                <div class="cell p-0 position-relative" v-if="isTitle" style="width: 225px">
                                    <div class="qu-remove-border editable-div single-line" contenteditable="true"
                                        v-html="question.title" @focus="onFocus"
                                        @blur="onBlur($event, questionIndex, 'title')">
                                    </div>
                                </div>
                                <div class="cell p-0 position-relative" v-if="isTitle" style="width: 225px">
                                    <div class="qu-remove-border editable-div single-line" contenteditable="true"
                                        v-html="question.titleTts" @focus="onFocus"
                                        @blur="onBlur($event, questionIndex, 'titleTts')">
                                    </div>
                                </div>
                            </div>
                            <div v-for="(
desc, descIndex
                                ) in question.descriptions" v-if="isDescription" class="d-flex flex-row" :class="{
                                    'sticky-column': isStickyDescription,
                                }">
                                <div class="cell p-0 position-relative" style="width: 225px">
                                    <div class="qu-remove-border editable-div single-line" contenteditable="true"
                                        v-html="desc.title" @focus="onFocus"
                                        @blur="onBlur($event, questionIndex, 'desc', descIndex, 'title')">
                                    </div>
                                    <template
                                        v-if="permissions.permission_manage_topics || permissions.permission_manage_topics_content">
                                        <b-dropdown
                                            v-if="desc.image == null || desc.image.length == 0 || desc.image[0].image == null"
                                            class="position-absolute" style="right:-17px" variant="link" no-caret>
                                            <template #button-content>
                                                <feather-icon icon="UploadIcon" size="20"
                                                    class="qu-icon-grey curser-pointer"
                                                    @click="showFullScreenModal('', questionIndex, descIndex, 'desc')" />
                                            </template>
                                            <b-dropdown-item
                                                @click="triggerFileInput(imgUploadQuestion, imgUploadoptionIndex, uploadType)">
                                                <feather-icon icon="UploadIcon" />
                                                <span class="align-middle ml-50">Upload</span>
                                            </b-dropdown-item>
                                            <b-dropdown-item @click="showGalleryImages">
                                                <feather-icon icon="ImageIcon" />
                                                <span class="align-middle ml-50">Gallery Images</span>
                                            </b-dropdown-item>
                                            <b-dropdown-item @click="showAudiosGallery">
                                                <feather-icon icon="HeadphonesIcon" />
                                                <span class="align-middle ml-50"> Audio Gallery</span>
                                            </b-dropdown-item>
                                        </b-dropdown>
                                    </template>
                                    <input :ref="'ref_Input' + descIndex" type="file"
                                        accept="image/x-png,image/gif,image/jpeg,application/json,.json,image/svg+xml,.svg,.lottie,audio/*"
                                        class="d-none" @change="ImageRendererItems" />

                                        <div v-for="(audio, audioIndex) in desc.audio" 
                                        v-if="audio.path" 
                                        class="position-absolute" 
                                        style="right: 35px;" 
                                        @click="showFullScreenModal(audio.path, questionIndex, descIndex, 'desc','audio')">
                                        <feather-icon 
                                            icon="HeadphonesIcon" 
                                            size="20" 
                                            @click="showFullScreenModal(audio.path, questionIndex, descIndex, 'desc', 'audio')" 
                                            style="cursor: pointer;" 
                                        />
                                    </div>

                                    <div v-for="(img, imageIndex) in desc.image" class="position-absolute"
                                        style="right: 60px;">
                                        <div v-if="getFileExtensionFromUrl(img.url) === 'json'"
                                            @click="showFullScreenModal(img.url, questionIndex, descIndex, 'desc')"
                                            :style="{ cursor: 'pointer', }">
                                            <LottieView :url="img.url"
                                                @click="showFullScreenModal(img.url, questionIndex, descIndex, 'desc')"
                                                :key="keyForLottie" />
                                        </div>
                                        <div v-else-if="getFileExtensionFromUrl(img.url) === 'lottie'"
                                            @click="showFullScreenModal(img.url, questionIndex, descIndex, 'desc')"
                                            :style="{ cursor: 'pointer', }">
                                            <DoLottieView :url="img.url" :id="`description_${question._id}_${uuidv4()}`"
                                                :key="keyForLottie" />
                                        </div>
                                        <div v-else
                                            @click="showFullScreenModal(img.url, questionIndex, descIndex, 'desc')"
                                            :style="{ cursor: 'pointer', }">
                                            <img @click="showFullScreenModal(img.url, questionIndex, descIndex, 'desc')"
                                                :src="img.url" height="30" :alt="img.name" />
                                        </div>
                                    </div>
                                </div>
                                <div class="cell p-0 position-relative" style="width: 225px">
                                    <div class="qu-remove-border editable-div single-line" contenteditable="true"
                                        v-html="desc.tts" @focus="onFocus"
                                        @blur="onBlur($event, questionIndex, 'desc', descIndex, 'tts')">
                                    </div>
                                </div>
                            </div>

                            <div class="d-flex flex-row">
                                <div class="cell p-0 position-relative" style="width: 225px">
                                    <div class="qu-remove-border editable-div single-line" contenteditable="true"
                                        v-html="question.hint.text" @focus="onFocus"
                                        @blur="onBlur($event, questionIndex, 'hint')">
                                    </div>
                                    <template
                                        v-if="permissions.permission_manage_topics || permissions.permission_manage_topics_content">
                                        <b-dropdown
                                            v-if="question.hint.image == null || question.hint.image.length == 0 || question.hint.image[0].image == null"
                                            class="position-absolute" style="right:-17px" variant="link" no-caret>
                                            <template #button-content>
                                                <feather-icon icon="UploadIcon" size="20"
                                                    class="qu-icon-grey curser-pointer"
                                                    @click="showFullScreenModal('', questionIndex, null, 'hint')" />
                                                    

                                            </template>
                                            <b-dropdown-item
                                                @click="triggerFileInput(imgUploadQuestion, '', uploadType)">
                                                <feather-icon icon="UploadIcon" />
                                                <span class="align-middle ml-50">Upload</span>
                                            </b-dropdown-item>
                                            <b-dropdown-item @click="showGalleryImages">
                                                <feather-icon icon="ImageIcon" />
                                                   <span class="align-middle ml-50">Gallery Images</span>
                                            </b-dropdown-item>
                                            <b-dropdown-item @click="showAudiosGallery">
                                                <feather-icon   icon="HeadphonesIcon" />
                                                <span class="align-middle ml-50"> Audio Gallery</span>
                                            </b-dropdown-item>
                                        </b-dropdown>
                                    </template>

                                    <input :ref="'ref_Input_hint'" type="file"
                                        accept="image/x-png,image/gif,image/jpeg,application/json,.json,image/svg+xml,.svg,.lottie,audio/*"
                                        class="d-none" @change="ImageRendererItems" />
                                        <div
                                        v-if="question.hint.audio" 
                                        class="position-absolute" 
                                        style="right: 35px;" 
                                        @click="showFullScreenModal(question.hint.audio, questionIndex, '', 'hint', 'audio')">
                                        <feather-icon 
                                            icon="HeadphonesIcon" 
                                            size="20" 
                                            @click="showFullScreenModal(question.hint.audio, questionIndex, '', 'hint', 'audio')"  style="cursor: pointer;" 
                                        />
                                    </div>
                                    <div class="position-absolute" style="right: 60px;">
                                        <div v-if="getFileExtensionFromUrl(question.hint.image) === 'json'"
                                            @click="showFullScreenModal(question.hint.image, questionIndex, '', 'hint')"
                                            :style="{ cursor: 'pointer', }">
                                            <LottieView :url="question.hint.image"
                                                @click="showFullScreenModal(question.hint.image, questionIndex, '', 'hint')"
                                                :key="keyForLottie" />
                                        </div>
                                        <div v-else-if="getFileExtensionFromUrl(question.hint.image) === 'lottie'"
                                            @click="showFullScreenModal(question.hint.image, questionIndex, '', 'hint')"
                                            :style="{ cursor: 'pointer', }">
                                            <DoLottieView :url="question.hint.image"
                                                :id="`hint_${question._id}_${uuidv4()}`" :key="keyForLottie" />
                                        </div>

                                        <div v-else
                                            @click="showFullScreenModal(question.hint.image, questionIndex, '', 'hint')"
                                            :style="{ cursor: 'pointer', }">
                                            <img @click="showFullScreenModal(question.hint.image, questionIndex, '', 'hint')"
                                                :src="question.hint.image" height="30" />
                                        </div>
                                       
                                    </div>

                                </div>
                            </div>

                            <template v-if="isOption" v-for="(opt, optIndex) in question.options">
                                <div class="cell p-0 position-relative" style="width: 225px">
                                    <div class="qu-remove-border editable-div single-line" contenteditable="true"
                                        v-html="opt.title" @focus="onFocus"
                                        @blur="onBlur($event, questionIndex, 'opt', optIndex, 'title')">
                                    </div>
                                    <input :ref="'ref_Input_opt' + optIndex" type="file"
                                        accept="image/x-png,image/gif,image/jpeg application/json,.json,image/svg+xml,.svg,.lottie,audio/*"
                                        class="d-none" @change="ImageRendererItems" />

                                    <template
                                        v-if="permissions.permission_manage_topics || permissions.permission_manage_topics_content">
                                        <!-- <b-dropdown v-if="opt.image == null || opt.image == '' || !opt.audio || opt.audio === '' || opt.audio === null" -->
                                        <b-dropdown
                                            class="position-absolute" style="right:-17px" variant="link" no-caret>
                                            <template #button-content>
                                                <feather-icon icon="UploadIcon" size="20"
                                                    class="qu-icon-grey curser-pointer"
                                                    @click="showFullScreenModal('', questionIndex, optIndex, 'opt')" />
                                            </template>
                                            <b-dropdown-item
                                                @click="triggerFileInput(imgUploadQuestion, imgUploadoptionIndex, uploadType)">
                                                <feather-icon icon="UploadIcon" />
                                                <span class="align-middle ml-50">Upload</span>
                                            </b-dropdown-item>
                                            <b-dropdown-item @click="showGalleryImages">
                                                <feather-icon icon="ImageIcon" />
                                                <span class="align-middle ml-50">Gallery Images</span>
                                            </b-dropdown-item>
                                            <b-dropdown-item @click="showAudiosGallery">
                                                <feather-icon   icon="HeadphonesIcon" />
                                                <span class="align-middle ml-50"> Audio Gallery</span>
                                            </b-dropdown-item>
                                        </b-dropdown>
                                        <template>
                                            <div v-if="getFileExtensionFromUrl(opt.image) == 'json'"
                                                @click="showFullScreenModal(opt.image, questionIndex, optIndex, 'opt')"
                                                :style="{ right: '60px', cursor: 'pointer' }" class="position-absolute">
                                                <LottieView :url="opt.image" :key="keyForLottie" />
                                            </div>
                                            <div v-else-if="getFileExtensionFromUrl(opt.image) === 'lottie'"
                                                @click="showFullScreenModal(opt.image, questionIndex, optIndex, 'opt')"
                                                :style="{ right: '60px', cursor: 'pointer' }" class="position-absolute">
                                                <DoLottieView :url="opt.image"
                                                    :id="`option_${question._id}_${uuidv4()}`" :key="keyForLottie" />
                                            </div>
                                            <img v-else class="position-absolute" style="right: 60px;"
                                                @click="showFullScreenModal(opt.image, questionIndex, optIndex, 'opt')"
                                                :src="opt.image" height="30" :alt="opt.name" />
                                        </template>
                                        
                                        <div
                                        v-if="opt.audio" 
                                        class="position-absolute" 
                                        style="right: 35px;" 
                                        @click="showFullScreenModal(opt.audio, questionIndex, optIndex, 'opt', 'audio')">
                                        <feather-icon 
                                            icon="HeadphonesIcon" 
                                            size="20" 
                                            @click="showFullScreenModal(opt.audio, questionIndex, optIndex, 'opt', 'audio')"  style="cursor: pointer;" 
                                        />
                                    </div>

                                    </template>

                                </div>
                                <div class="cell p-0 position-relative" style="width: 225px">
                                    <div class="qu-remove-border editable-div single-line" contenteditable="true"
                                        v-html="opt.tts" @focus="onFocus"
                                        @blur="onBlur($event, questionIndex, 'opt', optIndex, 'tts')">
                                    </div>
                                </div>
                                <div class="cell" style="width: 70px">
                                    <b-form-checkbox v-model="opt.correct" class="m-auto"
                                        style="width: fit-content"></b-form-checkbox>
                                </div>
                            </template>
                        </div>
                    </draggable>
                </div>
            </div>
        </div>
        <div>
            <div class="d-flex" style="border: 1px solid #ebe9f1;margin-top:-5px;">
                <div class="py-1 px-1 w-100 curser-pointer d-flex" style="font-size: 1rem">
                    <div @click="addNewQuestion" class="d-flex"
                        v-if="permissions.permission_manage_topics || permissions.permission_manage_topics_content">
                        <feather-icon icon="PlusIcon" size="20" class="qu-icon-grey" />
                        New
                    </div>
                </div>
            </div>
            <SaveQuestion v-if="permissions.permission_manage_topics || permissions.permission_manage_topics_content"
                :updateQuestionsChanges="updateQuestionsChanges" :newQuestion="newQuestion" :questions="questions"
                :originalQuestions="originalQuestions" @saveQuestions="saveQuestions" />
        </div>

        <SeriesQuestions ref="seriesQuestionsComponent" :permissions="permissions" />
        <b-modal v-model="showFullScreen" size="lg">
            <b-button variant="primary" @click="triggerFileInput1(imgUploadQuestion, imgUploadoptionIndex, uploadType)">
                Upload
            </b-button>
            <template>
        <template>    
            <b-button
            v-if="mediaType !== 'audio'"
            class="ml-1"
            variant="primary"
            @click="showGalleryImages"
            >
            Gallery Images
            </b-button>
    
            <b-button
            v-else
            class="ml-1"
            variant="primary"
            @click="showAudiosGallery"
            >
            Audio Gallery
            </b-button>
        </template>
        </template>
            <b-button  v-if="mediaType !== 'audio'" class="ml-1" variant="danger"  @click="removeImage">
                Remove 
            </b-button>
            <b-button v-else class="ml-1" variant="danger"  @click="removeAudio">
                Remove audio
            </b-button>
            <!-- <div class="text-center mt-4 mb-2">
                <template v-if="mediaType == 'audio' && audioUrl">
                    <audio :key="`audio-${audioUrl}`" controls>
                        <source :src="audioUrl" type="audio/mpeg">
                        Your browser does not support the audio element.
                    </audio>
                </template>
                <template v-else-if="imageUrl">
                    <template v-if="getFileExtensionFromUrl(imageUrl) === 'json'">
                        <LottieView :url="imageUrl" :forModel="true" :key="keyForLottie" />
                    </template>
                    <template v-else-if="getFileExtensionFromUrl(imageUrl) === 'lottie'">
                        <DoLottieView :url="imageUrl" :forModel="true" :id="`model_${uuidv4()}`" :key="keyForLottie" />
                    </template>
                    <template v-else>
                        <img :key="imageUrl" :src="imageUrl" alt="Fullscreen" style="height: auto; max-width: 100%;" />
                    </template>
                </template>
                
                <feather-icon v-else @click="triggerFileInput(imgUploadQuestion, imgUploadoptionIndex, uploadType)"
                    icon="UploadIcon" size="50" class="qu-icon-grey curser-pointer" />
            </div> -->

                    <div class="text-center mt-4 mb-2">
            <template v-if="mediaType == 'audio'">
                <template v-if="audioUrl">
                    <audio :key="`audio-${audioUrl}`" controls>
                        <source :src="audioUrl" type="audio/mpeg">
                        Your browser does not support the audio element.
                    </audio>
                </template>
                <template v-else>
                    <feather-icon @click="triggerFileInput(imgUploadQuestion, imgUploadoptionIndex, uploadType)"
                        icon="UploadIcon" size="50" class="qu-icon-grey curser-pointer" />
                </template>
            </template>
            <template v-else-if="imageUrl">
                <template v-if="getFileExtensionFromUrl(imageUrl) === 'json'">
                    <LottieView :url="imageUrl" :forModel="true" :key="keyForLottie" />
                </template>
                <template v-else-if="getFileExtensionFromUrl(imageUrl) === 'lottie'">
                    <DoLottieView :url="imageUrl" :forModel="true" :id="`model_${uuidv4()}`" :key="keyForLottie" />
                </template>
                <template v-else>
                    <img :key="imageUrl" :src="imageUrl" alt="Fullscreen" style="height: auto; max-width: 100%;" />
                </template>
            </template>
            <template v-else>
                <feather-icon @click="triggerFileInput(imgUploadQuestion, imgUploadoptionIndex, uploadType)"
                    icon="UploadIcon" size="50" class="qu-icon-grey curser-pointer" />
            </template>
        </div>
    
            </b-modal>
        <zape-gallery-model :props_hideShowModel="showImage" :keyForLottie="keyForLottie" @modelClose="modelClose"
            @saveCategoryImage="saveCategoryImage" />
            
        <ZapeAudioGalleryModel :props_hideShowAudiosModel="showAudioGallery"       @audiosModelClose="audiosModelClose"
            @saveCategoryAudio="saveCategoryAudio" />
    </div>
</template>

<script>
import {
    BCard,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BFormGroup,
    BSpinner,
    BRow,
    BCol,
    BInputGroup,
    BIcon,
    BFormCheckbox,
    BFormSelect,
    BTooltip,
    BFormTextarea,
} from "bootstrap-vue";
import axios from "axios";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ClickOutside from "vue-click-outside";
import draggable from "vuedraggable";
import PreviewQuestions from "./PreviewQuestions.vue";
import MoveQuestionsModal from "./MoveQuestionsModal.vue";
import { BBreadcrumb } from "bootstrap-vue";
import Lottie from 'vue-lottie';
import Confetti from '../../../../public/Confetti.json'
import LottieView from "./LottieView.vue";
import DoLottieView from "./DoLottieView.vue";
import { v4 as uuidv4 } from 'uuid';
export default {
    components: {
        draggable,
        SaveQuestion: () => import("./SaveQuestion.vue"),
        SeriesQuestions: () => import("./SeriesQuestions.vue"),
        AddQuestionGptResponse: () => import("./AddQuestionGptResponse.vue"),
        ExportQuestionForGpt: () => import("./ExportQuestionForGpt.vue"),
        ZapeGalleryModel: () => import("../Model/ZapeGalleryModel.vue"),
        ZapeAudioGalleryModel: () => import("../Model/ZapeAudioGalleryModel.vue"),
        QuestionFeedbackModal: () => import("../SaveQuestionFeedbackModal.vue"),
        QuestionLogModal: () => import("../ViewQuestionLogModal.vue"),
        BButton,
        PreviewQuestions,
        BFormInput,
        BFormGroup,
        BInputGroup,
        BTable,
        BDropdown,
        BDropdownItem,
        BOverlay,
        BSpinner,
        BCard,
        BRow,
        BCol,
        BIcon,
        ToastificationContent,
        BFormCheckbox,
        BFormSelect,
        BTooltip,
        BFormTextarea,
        BBreadcrumb,
        Lottie,
        LottieView,
        DoLottieView,
        uuidv4,
        MoveQuestionsModal
    },
    props: {
        items: {
            type: Array,
            required: true
        },
        permissions: {
            type: Object,
            required: true
        }
    },
    directives: {
        ClickOutside,
    },
    data() {
        return {
            alertMessageToast: "",
            show: false,
            bearerToken: "",
            questions: [],
            themes: [],
            formatType: [
                { value: "text", text: "text" },
                { value: "image", text: "image" },
            ],
            descriptionCount: 1,
            optionCount: 1,
            tableCheckbox: false,
            originalQuestions: [],
            isFieldDropdown: false,
            isTitle: true,
            isDescription: true,
            isOption: true,
            isSelectedQuestion: {},
            isStickyNumber: false,
            isStickyTitle: false,
            isStickyDescription: false,
            drag: false,
            audioUrl: null,
            dragIndex: null,
            dropIndex: null,
            errorMsg: "",
            imgUploadQuestion: null,
            imgUploadoptionIndex: null,
            imageUrl: null,
            uploadType: null,
            showFullScreen: false,
            showImage: false,
            showAudioGallery: false,
            confettiOptions: {
                loop: true,
                autoplay: true,
                animationData: Confetti
            },
            lottieJson: {},
            keyForLottie: 0,
            keyForDoLottie: 0,
            findText: "",
            replaceText: "",
            matchCount: 0,
            foundTexts: [],
            modifiedTexts: [],
            selectedColumn: "title",
            selectedQuestionForLog: "",
            isQuestionLogModalOpen: false,
            levelFeedBackData: [],
            AllQuestionsJson: [],
            singleQuestionJson: {},
            isQuestionFeedbackModalOpen: false,
            mediaType:null,
            selectedQuestionsToMove:[],
            moveQuestionsmodalKey:null
        };
    },

    created() {
 
        let id = this.$route.params.questionId;
        this.questionColorChange = id;
    },

    
    computed: {
        isDisableAction() {
        const selectedQuestions = this.questions.filter(
            (question) => question.checkbox
        ).length;
        return !selectedQuestions; 
        },

        formattedThemes() {
            return this.themes.map((theme) => ({
                value: theme.name,
                text: theme.name,
            }));
        },

        isSaveDisabled() {
            return this.foundTexts.length === 0 || this.modifiedTexts.length === 0;
        },

    },


    watch: {
        findText(newVal) {
            if (!newVal) {
                this.foundTexts = [];
                this.modifiedTexts = [];
            }
        },
        replaceText(newVal) {
            if (!newVal) {
                this.modifiedTexts = [];
            }
        }
    },

    mounted() {
        window.addEventListener("beforeunload", this.handleBeforeUnload);
        this.bearerToken = "Bearer " + localStorage.getItem("_t");
        this.fetch();
        this.getLevelFeedback();
    },
    beforeDestroy() {
        window.removeEventListener("beforeunload", this.handleBeforeUnload);
    },
    methods: {
        uuidv4,
        handlePreviewModalClose(){
            this.getLevelFeedback();
        },
        ResetQuestionsCheckBox(){
            this.questions.forEach((question) => {
                question.checkbox = false;
            });
        },
        getUpdatedData(){
            this.fetch();
            this.getLevelFeedback(); 
            
        },
      async  moveQuestions(){
            const selectedQuestions = this.questions.filter(
            (question) => question.checkbox
        ) 
       this.selectedQuestionsToMove = selectedQuestions;
        if(this.selectedQuestionsToMove.length > 0){
           if(this.isConsecutiveSequence(selectedQuestions)){ 
            let updatedQuestion = await this.detectChangesInQuestions();
            let newQuestion = await this.newQuestion();
            if (updatedQuestion.length == 0 && newQuestion.length == 0) {
                this.$bvModal.show('moveQuestionsModal');
            } else {
                Swal.fire({
                title: 'Attention!',
                text: 'You have unsaved changes! please save first.',
                icon: 'error', 
                confirmButtonText: 'OK',
            }); 
            }

           } else { 
            Swal.fire({
                title: 'Attention!',
                text: 'Please select questions in a sequence',
                icon: 'error', 
                confirmButtonText: 'OK',
            }); 
           }
            
        } else {
            console.error('No questions selected to move')
        }
        },


        detectChangesInQuestions() {
    const updatedMap = new Map(this.questions.map(obj => [obj._id, obj]));
    let updateQuestion = [];

    const excludeProperties = (obj, propertiesToIgnore) => {
        const newObj = { ...obj };
        propertiesToIgnore.forEach(prop => delete newObj[prop]);
        return newObj;
    };

    const propertiesToIgnore = ['checkbox'];

    for (let i = 0; i < this.originalQuestions.length; i++) {
        const originalObj = this.originalQuestions[i];
        const updatedObj = updatedMap.get(originalObj._id);

        if (updatedObj) {
            const originalCopy = excludeProperties(originalObj, propertiesToIgnore);
            const updatedCopy = excludeProperties(updatedObj, propertiesToIgnore);

            if (JSON.stringify(originalCopy) !== JSON.stringify(updatedCopy)) {
                updateQuestion.push(updatedObj);
            }
        }
    }

    return updateQuestion;
},


         isConsecutiveSequence(questions) { 
        questions.sort((a, b) => a.questionNo - b.questionNo);
        for (let i = 0; i < questions.length - 1; i++) {
            if (questions[i + 1].questionNo !== questions[i].questionNo + 1) {
                return false;  
            }
        }
        return true;  
        },
     
        getBackgroundColor(questionId) {
    const feedbacks = this.levelFeedBackData.filter(feedback => feedback.questionId === questionId);
    if (feedbacks.length === 0) {
        return 'unset';
    }

    const hasHidden = feedbacks.some(feedback => 
        feedback.reply && 
        feedback.reply[0] && 
        feedback.reply[0].hideQuestion === true
    );

    const allNotHidden = feedbacks.every(feedback => 
        feedback.reply && 
        feedback.reply[0] && 
        (feedback.reply[0].hideQuestion === false || 
         !feedback.reply[0].hasOwnProperty('hideQuestion'))
    );

    if (hasHidden) {
        return '#ff4d4d';
    } else if (allNotHidden) {
        return 'orange';
    } else {
        return 'unset';
    }
},
        // getBackgroundColor(questionId) {
  
        //     const feedbacks = this.levelFeedBackData.filter(feedback => feedback.questionId === questionId);
        //     if (feedbacks.length === 0) {
        //         console.log('sldsdkddsdsd')
        //         return 'unset';
        //     }

        //     const hasHidden = feedbacks.some(feedback => feedback.reply[0].hideQuestion === true);

        //     console.log('hasHidden', hasHidden)
        //     const allNotHidden = feedbacks.every(feedback => feedback.reply[0].hideQuestion === false);
        //     console.log('allNotHidden', allNotHidden)
        //     if (hasHidden) {
        //         return '#ff4d4d';
        //     } else if (allNotHidden) {
        //         return 'orange';
        //     } else {
        //         return 'unset';
        //     }
        // },

        closeViewJsonModel() {
            this.$root.$emit("bv::hide::modal", "ViewQuestionJsonModal");
        },
        ViewQuestionLogModal() {
            this.$root.$emit("bv::hide::modal", "ViewQuestionLogModal");
        },

        ViewQuestionLog(question) {
            this.selectedQuestionForLog = question;
        },

        ViewQuestionJson(question) {
            if (this.AllQuestionsJson.length > 0) {
                const questionId = question._id;
                const foundQuestion = this.AllQuestionsJson.find(q => q._id === questionId);

                if (foundQuestion) {
                    this.singleQuestionJson = JSON.stringify(foundQuestion, null, 2);
                } else {
                    this.singleQuestionJson = "No json found for this question.";
                }
            } else {
                console.log('No Questions found');
            }
        },

        async getLevelFeedback() {
            try {
                const response = await axios.get(`${process.env.VUE_APP_API_URL}/get-level-feedback`, {
                    params: {
                        levelId: this.$route.params.levelId,
                    },
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("_t")}`,
                    },
                });

                if (response.data.success) {
                    if (response.data && response.data.feedbacks) {
                        this.levelFeedBackData = response.data.feedbacks;
                    }

                } else {
                    this.alertMessageToast = 'Error in fetching feedback!';
                    this.showToast("danger");
                    console.error('No feedbacks found or error fetching feedback');
                }
            } catch (error) {
                console.error('Error fetching level feedback:', error);
            } finally {
            }
        },

        setColumnName(columnName) {
            this.selectedColumn = columnName;

        },

        handleFeedbackResponse(response) {
            if (response && response.status == 'success') {
                this.alertMessageToast = 'Feedback added successfully';
                this.showToast("success");
                this.getLevelFeedback();
            }
            else if (response == 'You do not have permission to give feedback.') {
                this.alertMessageToast = 'You do not have permission to give feedback.';
                this.showToast("danger");
            }
            else {
                this.alertMessageToast = 'Error in adding feedback!';
                this.showToast("danger");

            }
        },

        addFeedback(question) {
            this.selectedQuestionForFeedback = question;
            this.isQuestionFeedbackModalOpen = true
        },

        closeFeedbackModal() {
            this.isQuestionFeedbackModalOpen = false
        },
        closeLogModal() {
            this.isQuestionLogModalOpen = false
        },

        onFind() {
            const searchText = this.findText ? this.findText.toLowerCase() : "";
            if (!searchText) {
                console.log("Error: 'Find Text' is empty.");
                this.foundTexts = [];
                this.matchCount = 0;
                return;
            }

            let totalMatchCount = 0;
            this.foundTexts = this.questions
                .filter((item) => {

                    return item[this.selectedColumn] && item[this.selectedColumn].toLowerCase().includes(searchText);
                })
                .map((item) => {
                    const regex = new RegExp(`(${searchText})`, "gi");
                    const highlightedTitle = item[this.selectedColumn].replace(
                        regex,
                        '<span style="background-color: yellow;">$1</span>'
                    );

                    const matches = item[this.selectedColumn].match(regex);
                    totalMatchCount += matches ? matches.length : 0;

                    return {
                        _id: item._id,
                        [this.selectedColumn]: highlightedTitle
                    };
                });

            this.matchCount = totalMatchCount;
        },

        onConfirmReplacement() {

            if (this.modifiedTexts.length === 0) {
                console.log('Nothing to replace as modifiedTexts is empty');
                return;
            }

            this.modifiedTexts.forEach((modifiedItem) => {
                const question = this.questions.find((item) => item._id === modifiedItem._id);

                if (question) {

                    const cleanedTitle = modifiedItem[this.selectedColumn].replace(/<[^>]*>/g, "");

                    question[this.selectedColumn] = cleanedTitle;
                }
            });

            this.alertMessageToast = 'Text replaced successfully';
            this.showToast("success");
        },

        onReplace() {

            const searchText = this.findText ? this.findText.toLowerCase() : "";
            const replaceText = this.replaceText || "";

            if (!searchText || !replaceText) {
                console.log("Error: Both 'Find Text' and 'Replace Text' must be non-empty.");
                this.modifiedTexts = [];
                return;
            }

            if (this.foundTexts.length > 0) {
                this.modifiedTexts = this.foundTexts.map((item) => {
                    const regex = new RegExp(`(${searchText})`, "gi");

                    let replacedTitle = item[this.selectedColumn].replace(
                        regex,
                        replaceText
                    );
                    replacedTitle = replacedTitle.replace(
                        new RegExp(`(${replaceText})`, "gi"),
                        '<span style="background-color: red; color: white;">$1</span>'
                    );

                    return {
                        _id: item._id,
                        [this.selectedColumn]: replacedTitle
                    };
                });
            }
        },


        onFindReplaceModalClose() {
            this.findText = '';
            this.replaceText = '';
            this.foundTexts = [];
            this.modifiedTexts = [];
            this.matchCount = 0;
        },

        fetchQuestionList() {
        },
        copyDescitpionTts(index) {
            this.questions.forEach((question) => {
                question.descriptions[index].tts = question.descriptions[index].title;
            });
        },

        getFileExtensionFromUrl(url) {
            if (!url) return '';
            const path = url.split('?')[0].split('#')[0];
            const extension = path.split('.').pop();

            if (extension === path) return '';

            return extension;
        },

        copyOptionTts(index) {
            this.questions.forEach((question) => {
                question.options[index].tts = question.options[index].title;
            });
        },
        copyQuestionTts() {
            this.questions.forEach((question) => {
                question.titleTts = question.title;
            });
        },
        removeAudio() {

            if (this.uploadType == 'desc') {
                let audioObject = [];
                this.questions[this.imgUploadQuestion].descriptions[this.imgUploadoptionIndex].audio = audioObject;
            } else if (this.uploadType == 'hint') {
                this.questions[this.imgUploadQuestion].hint.audio = null;
            } else {
                this.questions[this.imgUploadQuestion].options[this.imgUploadoptionIndex].audio = null;
            }
            this.audioUrl = '';
        },
        removeImage() {

            if (this.uploadType == 'desc') {
                let imageObject = [];
                this.questions[this.imgUploadQuestion].descriptions[this.imgUploadoptionIndex].image = imageObject;
            } else if (this.uploadType == 'hint') {
                this.questions[this.imgUploadQuestion].hint.image = null;
            } else {
                this.questions[this.imgUploadQuestion].options[this.imgUploadoptionIndex].image = null;
            }
            this.imageUrl = '';
        },
        showGalleryImages() {
            this.showImage = true;
        },
        showAudiosGallery() {
            this.showAudioGallery = true;
        },
        audiosModelClose(modelState) {
            this.showAudioGallery = modelState;
        },
        modelClose(modelState) {
            this.showImage = modelState;
        },

        saveCategoryAudio(categoryAudio) {
            if (this.uploadType == 'desc') {
                let audioObject = [{
                    name: 'gallery audio',
                    path: categoryAudio,
                }];
                this.questions[this.imgUploadQuestion].descriptions[this.imgUploadoptionIndex].audio = audioObject;

            } else if (this.uploadType == 'hint') {
                this.questions[this.imgUploadQuestion].hint.audio = categoryAudio;
            } else {
                this.questions[this.imgUploadQuestion].options[this.imgUploadoptionIndex].audio = categoryAudio;
            }
            this.audioUrl = categoryAudio; 
        },

        saveCategoryImage(categoryImage) {
            if (this.uploadType == 'desc') {
                let imageObject = [{
                    name: 'gallery-image',
                    url: categoryImage,
                }];
                this.questions[this.imgUploadQuestion].descriptions[this.imgUploadoptionIndex].image = imageObject;

            } else if (this.uploadType == 'hint') {
                this.questions[this.imgUploadQuestion].hint.image = categoryImage;
            } else {
                this.questions[this.imgUploadQuestion].options[this.imgUploadoptionIndex].image = categoryImage;
            }
            this.imageUrl = categoryImage;
            this.keyForLottie = this.keyForLottie + 1;
        },
      
        showFullScreenModal(url, questionIndex, index = null, type, mediaType = null) 
        {
            if(mediaType == 'audio'){
                this.audioUrl = url;
            } else {
                this.imageUrl = url;
            }
            this.mediaType= mediaType;
            this.imgUploadQuestion = questionIndex;
            this.imgUploadoptionIndex = index;
            this.uploadType = type;
            if (url) {
                this.showFullScreen = true;
            }
        },
        triggerFileInput1(questionIndex, index, type) {
        this.imgUploadQuestion = questionIndex;
        this.imgUploadoptionIndex = index;
        this.uploadType = type;

        let fileInput;
        if (type === 'desc') {
            fileInput = this.$refs['ref_Input' + index][0];
        } else if (type === 'hint') {
            fileInput = this.$refs['ref_Input_hint' + index][0];
        } else {
            fileInput = this.$refs['ref_Input_opt' + index][0];
        }
 
        if (this.mediaType === 'audio') {
            fileInput.setAttribute('accept', 'audio/*');
        } else {
            fileInput.setAttribute('accept', 'image/*');
        }

        fileInput.click();
    },

        triggerFileInput(questionIndex, index, type) {
            this.imgUploadQuestion = questionIndex;
            this.imgUploadoptionIndex = index;
            this.uploadType = type;
            if (type == 'desc') {
                this.$refs['ref_Input' + index][0].click();
            } else if (type == 'hint') {
                this.$refs['ref_Input_hint' + index][0].click();
            }
            else {
                this.$refs['ref_Input_opt' + index][0].click();
            }
        },
        async uploadFile(file, url, key, ext) {
            try {
                const response = await axios.put(url, file, {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': file.type,
                    },
                });

                if (response.status !== 200) {
                    console.error('Error uploading file:', response);
                }
                let s3Url = 'https://zape02.s3.ap-southeast-1.amazonaws.com/' + key;
                if (this.uploadType == 'desc') {
                    if(ext == 'mp3' || ext == 'wav' || ext == 'ogg' || ext == 'm4a') {
                        let audioObject = [{
                        name: file.name,
                        path: s3Url,
                    }];
                    this.questions[this.imgUploadQuestion].descriptions[this.imgUploadoptionIndex].audio = audioObject;
                    } else {
                        let imageObject = [{
                        name: file.name,
                        url: s3Url,
                    }];
                    this.questions[this.imgUploadQuestion].descriptions[this.imgUploadoptionIndex].image = imageObject;
                    }
                 
                } else if (this.uploadType == 'hint') {
                    if(ext == 'mp3' || ext == 'wav' || ext == 'ogg' || ext == 'm4a') {
                        this.questions[this.imgUploadQuestion].hint.audio = s3Url;
                    } else {
                    this.questions[this.imgUploadQuestion].hint.image = s3Url;
                    }
                } else {
                    if(ext == 'mp3' || ext == 'wav' || ext == 'ogg' || ext == 'm4a') {
                        this.questions[this.imgUploadQuestion].options[this.imgUploadoptionIndex].audio = s3Url;
                    } else {
                        this.questions[this.imgUploadQuestion].options[this.imgUploadoptionIndex].image = s3Url;
                    }  
                }
                if(ext == 'mp3' || ext == 'wav' || ext == 'ogg' || ext == 'm4a') {
                    this.audioUrl = s3Url;
                    this.saveAudioInGallery(s3Url, file.name, ext)
                } else {
                    this.imageUrl = s3Url;
                    this.createGalleryImage(s3Url, file.name, ext)
                }
                
                this.keyForLottie += 1;
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        },


        async saveAudioInGallery(s3Url, fileName, ext) {
        try {
            if (!this.bearerToken) {
                throw new Error("Authentication failed");
            }

            const galleryObj = {
                name: fileName,
                url: s3Url,
                audioFormat: ext,
            };

            const response = await axios.post(
                `${process.env.VUE_APP_API_URL}/gallery/audio/create`,
                galleryObj,
                {
                    headers: {
                        Authorization: this.bearerToken,
                        "Content-Type": "application/json",
                    }
                }
            );

            if (response.data?.message) {
                console.log(response.data.message);
            }
        } catch (error) {
            console.error(error.response?.data?.message || "Failed to save audio");
        }
    },


        async createGalleryImage(s3Url, fileName, ext) {
            try {
                let galleryObj = {
                    name: fileName,
                    url: s3Url,
                    imageFormat: ext
                }
                await axios.post(process.env.VUE_APP_API_URL + '/gallery/image/create', galleryObj, {
                    headers: {
                        Authorization: this.bearerToken,
                    },
                });
            } catch (error) {
                console.error('Error saving image to gallery', error);
            }
        },
        async ImageRendererItems(event) {
            const file = event.target.files[0];
            if (file) {
                const ext = file.name.split('.').pop().toLowerCase();
                let fileExtension;
                if (ext === 'lottie') {
                    fileExtension = 'lottie'
                } else {
                    fileExtension = this.getFileExtension(file.type);
                }
                try {
                    const response = await axios.post(process.env.VUE_APP_API_URL + '/s3/generate-presigned-url', {
                        type: file.type,
                        ext: fileExtension
                    }, {
                        headers: {
                            Authorization: this.bearerToken,
                        },
                    });
                    const { url, key } = response.data;
                    let extention = fileExtension === 'json' ? 'lottie' : fileExtension
                    await this.uploadFile(file, url, key, extention);
                } catch (error) {
                    console.error('Error getting pre-signed URL:', error);
                }
            }
            event.target.value = null;
        },

        getFileExtension(mimeType) {
            const mimeToExtension = {
                'image/jpeg': 'jpg',
                'image/png': 'png',
                'image/gif': 'gif',
                'image/svg+xml': 'svg',
                '.svg': 'svg',
                'application/json': 'json',
                '.json': 'json',
                '.lottie': 'lottie',
                'lottie': 'lottie',
                'audio/mpeg': 'mp3',
                'audio/wav': 'wav',
                'audio/ogg': 'ogg',
                'audio/aac': 'aac',
                'audio/flac': 'flac',
                'audio/x-wav': 'wav',
                'audio/x-m4a': 'm4a',
                'audio/webm': 'webm',
            };
            return mimeToExtension[mimeType] || 'png';
        },


        resetGptModel() {
            this.errorMsg = "";
        },

        async createQuestionJson(text) { 
            const payload = { text: text };
            try {
                const resp = await axios.post(process.env.VUE_APP_API_URL + `/question/text/json`, payload, {
                    headers: { Authorization: this.bearerToken },
                });
                let gptQuestion = resp.data; 
                if (gptQuestion.length > 0) {
                    const { questionNo, incrementDescAndOpt } = this.calculateQuestionNoAndFlag();
                    this.assignQuestionNumbers(gptQuestion, questionNo);
                    if (incrementDescAndOpt) { 
                        this.adjustDescriptionsAndOptions(gptQuestion);
                    } else {
                        this.setInitialDescriptionAndOptionCounts(gptQuestion);
                        this.questions = gptQuestion;
                    }
                    this.$bvModal.hide("gpt-questions-modal");
                } else {
                    this.errorMsg = "Invalid text format";
                }
            } catch (error) {
                this.errorMsg = "Invalid text format";
            }
        },

        calculateQuestionNoAndFlag() {
            let questionNo = 1;
            let incrementDescAndOpt = false;
            if (this.questions.length > 0) {
                questionNo = this.questions[this.questions.length - 1].questionNo + 1;
                incrementDescAndOpt = true;
            }
            return { questionNo, incrementDescAndOpt };
        },

        assignQuestionNumbers(gptQuestion, questionNo) {
            gptQuestion.forEach((question, index) => {
                question.questionNo = questionNo + index;
            });
        },

        adjustDescriptionsAndOptions(gptQuestion) {
            this.adjustDescriptions(gptQuestion);
            this.adjustOptions(gptQuestion);
            this.updateDescriptionAndOptionCounts();
            this.questions.push(...gptQuestion);
        },

        adjustDescriptions(gptQuestion) {
            const descriptionCount = gptQuestion[0].descriptions.length;
            if (this.descriptionCount !== descriptionCount) {
                if (this.descriptionCount > descriptionCount) {
                    this.addExtraDescriptions(gptQuestion, descriptionCount);
                } else {
                    this.addExtraDescriptionsToExistingQuestions(descriptionCount);
                }
            }
        },

        addExtraDescriptions(gptQuestion, descriptionCount) {
            for (let index = descriptionCount; index < this.descriptionCount; index++) {
                gptQuestion.forEach((question) => {
                    question.descriptions.push({
                        title: "",
                        tts: "",
                        type: "text",
                        image: null,
                    });
                });
            }
        },

        addExtraDescriptionsToExistingQuestions(descriptionCount) {
            for (let index = this.descriptionCount; index < descriptionCount; index++) {
                this.questions.forEach((question) => {
                    question.descriptions.push({
                        title: "",
                        tts: "",
                        type: "text",
                        image: null,
                    });
                });
            }
        },

        adjustOptions(gptQuestion) { 
            const optionCount = gptQuestion[0].options.length; 
            if (this.optionCount !== optionCount) {
                if (this.optionCount > optionCount) {
                    this.addExtraOptions(gptQuestion, optionCount);
                } else {
                    this.addExtraOptionsToExistingQuestions(optionCount);
                }
            }
        },

        addExtraOptions(gptQuestion, optionCount) {
            for (let index = optionCount; index < this.optionCount; index++) {
                gptQuestion.forEach((question) => {
                    question.options.push({
                        title: "",
                        tts: "",
                        type: "text",
                        image: null,
                    });
                });
            }
        },

        addExtraOptionsToExistingQuestions(optionCount) {
            for (let index = this.optionCount; index < optionCount; index++) {
                this.questions.forEach((question) => {
                    question.options.push({
                        title: "",
                        tts: "",
                        type: "text",
                        image: null,
                    });
                });
            }
        },

        updateDescriptionAndOptionCounts() {
            this.descriptionCount = this.questions[0].descriptions.length;
            this.optionCount = this.questions[0].options.length;
        },

        setInitialDescriptionAndOptionCounts(gptQuestion) {
            this.descriptionCount = gptQuestion[0].descriptions.length;
            this.optionCount = gptQuestion[0].options.length;
        },

        onFocus(event) {
            const target = event.target;
            target.style.background = '#FFFFFF';
            target.style.position = 'absolute';
            target.style.top = 0;
            target.style.left = 0;
            target.style.width = '225px';
            target.style.zIndex = '10';
            target.style.minHeight = '38px';
            target.style.padding = '20px';
            target.style.fontSize = '14px';
            target.style.whiteSpace = 'pre-wrap';
            target.style.overflow = 'auto';
            target.style.border = '1px solid #ebe9f1';
            target.style.maxHeight = '';
            target.style.removeProperty('height');
            target.style.setProperty('max-height', 'none', 'important');
        },

        onBlur(event, index, name, childIndex, childName) {
            const target = event.target;
            target.style.background = '';
            target.style.position = '';
            target.style.top = '';
            target.style.left = '';
            target.style.width = '';
            target.style.minHeight = '';
            target.style.zIndex = '';
            target.style.height = '';
            target.style.padding = '';
            target.style.fontSize = '';
            target.style.whiteSpace = 'pre-wrap';
            target.style.overflow = 'hidden';
            target.style.border = '';
            target.style.maxHeight = '38px';
            this.updateQuestionValues(event, index, name, childIndex, childName);
        },


        stripHtml(html) {
            const htmlWithSpaces = html.replace(/&nbsp;|\u00a0/g, ' ');
            const textWithoutTags = htmlWithSpaces.replace(/<[^>]*>?/gm, '');
            return textWithoutTags;
        },

        updateQuestionValues(event, index, name, childIndex, childName) {
            const innerHtml = event.target.textContent.trim();
            const text = this.stripHtml(innerHtml);
            if (name === 'title') {
                this.questions[index].title = text;
            }

            if (name === 'titleTts') {
                this.questions[index].titleTts = text;
            }
            if (name === 'hint') {
                this.questions[index].hint.text = text;
            }

            if (name === 'desc') {
                if (childName === 'title') {
                    this.questions[index].descriptions[childIndex].title = text;
                }

                if (childName === 'tts') {
                    this.questions[index].descriptions[childIndex].tts = text;
                }
            }

            if (name === 'opt') {
                if (childName === 'title') {
                    this.questions[index].options[childIndex].title = text;
                }

                if (childName === 'tts') {
                    this.questions[index].options[childIndex].tts = text;
                }
            }
        },

        onDragStart(event) {
            this.dragIndex = event.oldIndex;
        },

        onDragEnd(event) {
            this.dropIndex = event.newIndex;
            this.drag = false;
            this.questions.forEach((question, index) => {
                question.questionNo = index + 1;
            });
        },

        updateStickTitle() {
            this.isStickyTitle = !this.isStickyTitle;
        },

        updateStickFunction() {
            this.isStickyNumber = !this.isStickyNumber;
        },

        duplicateQuestion(question) {
            let duplicateObject = question;
            let newQuestion = JSON.parse(JSON.stringify(duplicateObject));
            delete newQuestion._id;
            newQuestion.questionNo =
                this.questions[this.questions.length - 1].questionNo + 1;
            this.questions.push(newQuestion);
        },

        hideFields() {
            this.isFieldDropdown = false;
        },

        hideShowField() {
            this.isFieldDropdown = !this.isFieldDropdown;
        },

        async handleBeforeUnload(event) {
            let updatedQuestion = await this.updateQuestionsChanges();
            let newQuestion = await this.newQuestion();
            if (updatedQuestion.length !== 0 || newQuestion.length !== 0) {
                const message =
                    "You have unsaved changes. Are you sure you want to leave?";
                event.returnValue = message;
                return message;
            }
        },

        updateQuestionsChanges() {
            const updatedArray = this.questions;
            let updateQuestion = [];
            for (let i = 0; i < this.originalQuestions.length; i++) {
                const originalObj = this.originalQuestions[i];
                const updatedObj = updatedArray.find(
                    (obj) => obj._id === originalObj._id
                );
                if (
                    updatedObj &&
                    JSON.stringify(originalObj) !== JSON.stringify(updatedObj)
                ) {
                    updateQuestion.push(updatedObj);
                }
            }
            return updateQuestion;
        },

        newQuestion() {
            const newQuestion = this.questions.filter(
                (obj) => obj._id === undefined
            );
            return newQuestion;
        },


        async saveQuestions() {
            let updatedQuestion = await this.updateQuestionsChanges();

            let newQuestion = await this.newQuestion();

            if (updatedQuestion.length === 0 && newQuestion.length === 0) {
                return "No changes or new questions found.";
            } else {
                const payload = {
                    updateQuestions: updatedQuestion,
                    newQuestions: newQuestion,
                    questions: this.questions,
                    levelId: this.$route.params.levelId,
                };
                try {
                    const res = await axios.put(
                        process.env.VUE_APP_API_URL + `/questions`,
                        payload,
                        {
                            headers: {
                                Authorization: this.bearerToken,
                            },
                        }
                    );
                    this.$bvModal.hide("save-question-modal");
                    await this.getQuestions();
                    this.alertMessageToast = res.data.message;
                    this.showToast("success"); 
                    if (res.data.question_log_errors && res.data.question_log_errors.length > 0) {
                        this.alertMessageToast = 'Error while saving question logs';
                        this.showToast("danger");
                    }

                } catch (error) { }
            }
        },
        confirmDelete() {
            const selectedQuestions = this.questions.filter(
                (question) => question.checkbox
            ).length;
            if (!selectedQuestions) {
                return;
            }

            let msg =
                selectedQuestions > 1
                    ? "Do You want to delete questions!"
                    : "Do You want to delete question!";
            Swal.fire({
                title: "Are you sure?",
                text: msg,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                cancelButtonColor: "#3085d6",
                confirmButtonText: "Yes, delete it!",
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteQuestions();
                }
            });
        },
        toggleSelectedQuestion() {
            this.tableCheckbox = false;
        },
        checkUncheckQuestion() {
            this.questions.forEach((question) => {
                question.checkbox = this.tableCheckbox;
            });
        },
        addDescription() {
            this.questions.forEach((question) => {
                question.descriptions.push({
                    title: "",
                    tts: "",
                    type: "text",
                    image: null,
                });
            });
            this.descriptionCount = this.descriptionCount + 1;
        },
        removeDescription(index) {
            const indexToRemove = index;

            this.questions.forEach((question) => {
                if (
                    question.descriptions &&
                    question.descriptions.length > indexToRemove
                ) {
                    question.descriptions.splice(indexToRemove, 1);
                }
            });
            this.descriptionCount = this.descriptionCount - 1;
        },
        addOption() {
            this.questions.forEach((question) => {
                question.options.push({
                    title: "",
                    tts: "",
                    type: "text",
                    image: null,
                    correct: false,
                });
            });
            this.optionCount = this.optionCount + 1;
        },
        removeOption(index) {
            const indexToRemove = index;

            this.questions.forEach((question) => {
                if (
                    question.options &&
                    question.options.length > indexToRemove
                ) {
                    question.options.splice(indexToRemove, 1);
                }
            });
            this.optionCount = this.optionCount - 1;
        },
        async addQuestions(question) {
            try {
                const payload = {
                    levelId: this.$route.params.levelId,
                    questions: [question],
                };
                await axios.post(
                    process.env.VUE_APP_API_URL + "/questions",
                    payload,
                    {
                        headers: {
                            Authorization:
                                "Bearer " + localStorage.getItem("_t"),
                        },
                    }
                );
                await this.getQuestions();
            } catch (error) { }
        },
        async addNewQuestion() {
            let newObject = await this.createObject();
            let newQuestion = JSON.parse(JSON.stringify(newObject));
            this.questions.push(newQuestion);
        },
        createObject() {
            let questionNo = this.questions.length + 1;
            if (this.questions.length > 0) {
                questionNo =
                    this.questions[this.questions.length - 1].questionNo + 1;
            }

            const obj = {
                checkbox: false,
                theme: "Basic",
                questionNo: questionNo,
                questionType: "MCQs",
                title: "",
                titleTts: "",
                hint: {
                    text: "",
                    image: ""
                },
                descriptions: [],
                options: [],
            };

            for (let i = 0; i < this.descriptionCount; i++) {
                obj.descriptions.push({
                    title: "",
                    tts: "",
                    type: "text",
                    image: [],
                });
            }

            for (let i = 0; i < this.optionCount; i++) {
                obj.options.push({
                    title: "",
                    tts: "",
                    type: "text",
                    image: "",
                    correct: false,
                });
            }

            return obj;
        },
        async getQuestions() {
            this.show = true;
            const payload = {};
            payload.search = this.searchQuery;
            try {
                const res = await axios.get(
                    process.env.VUE_APP_API_URL +
                    `/questions/${this.$route.params.levelId}/MCQs`,
                    {
                        headers: {
                            Authorization: this.bearerToken,
                        },
                    }
                );
                if (res.data && res.data.questions.length > 0) {

                    this.AllQuestionsJson = res.data.questions

                    this.descriptionCount =
                        res.data.questions[0].descriptions.length;
                    this.optionCount =
                        res.data.questions[0].options.length;
                }

                let data = this.insertHint(res.data.questions)
                if (data) {
                    this.questions = data;
                } else {
                    this.questions = res.data.questions
                }
                this.originalQuestions = JSON.parse(
                    JSON.stringify(res.data.questions)
                );
                this.show = false;
            } catch (error) {
                this.show = false;
            }

        },


        insertHint(data) {
            data.forEach((question) => {
                if (!question.hint) {
                    this.$set(question, 'hint', {
                        text: "",
                        image: ""
                    });
                }
                return question;
            });
        },

        showToast(variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: "Notification",
                    icon: "BellIcon",
                    text: this.alertMessageToast,
                    variant,
                },
            });
        },
        async fetch() {
            await this.getQuestions();
            await this.fetchThemes();
            const seriesQuestionsComponent = this.$refs.seriesQuestionsComponent;
            if (seriesQuestionsComponent) {
                seriesQuestionsComponent.fetch();
            }
        },
        async fetchThemes(page = 1) {
            const payload = {};
            try {
                const res = await axios.post(
                    process.env.VUE_APP_API_URL + `/themes?page=${page}`,
                    payload,
                    {
                        headers: {
                            Authorization: this.bearerToken,
                        },
                    }
                );
                this.themes = res.data.themes.data;
            } catch (error) { }
        },
        async deleteQuestions() {
            const selectedIds = this.questions
                .filter((question) => question.checkbox && question._id)
                .map((question) => question._id);

            const payload = {
                ids: selectedIds,
            };
            try {
                await axios.post(
                    process.env.VUE_APP_API_URL + `/questions/delete`,
                    payload,
                    {
                        headers: {
                            Authorization: this.bearerToken,
                        },
                    }
                );
                this.questions = this.questions.filter(
                    (question) => !question.checkbox
                );
                this.selectedQuestions = [];
                this.tableCheckbox = false;
            } catch (error) { }
        },

    },

};

</script>
